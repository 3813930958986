<template>
  <div class="main-menu">
    <ElementMainMenu
      v-for="(item) in props.items"
      :key="`ElementMainMenu-${ item.id }`"
      :item="item"
    />
  </div>
</template>

<script setup lang="ts">
import ElementMainMenu from "./ElementMainMenuV2.vue";

interface IItem {
  id: String | Number
}
interface IProps {
  items: IItem[]
}
const props = withDefaults(defineProps<IProps>(), {
  items: () => []
});

</script>

<style scoped lang="scss">
.main-menu {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

@media (max-width: 1399px) {
  .main-menu {
    gap: 16px;
  }
}
@media (max-width: 1199px) {
  .main-menu {
    gap: 10px;
  }
}
</style>
