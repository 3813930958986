<template>
  <DropdownHeader
    :value="selectedCurrency"
    :options="optionsCurrency"
    @select="changeCurrency"
  />
</template>

<script setup lang="ts">
import type { ComputedRef } from 'vue';
import DropdownHeader from './_DropdownHeaderV2.vue';
import type { IOptionControlDropDown } from '~/types/header';

const selectedCurrency: ComputedRef<any> = computed(() => {
  return 'rub';
});
const optionsCurrency: ComputedRef<IOptionControlDropDown[]> = computed(() => {
  return [
    { title: '₽', value: 'rub' },
    { title: '$', value: 'usd' }
  ];
});
const changeCurrency = (_val: any) => { };
</script>
