<template>
  <div class="header-sidebar">
    <div class="--top-bar">
      <NuxtLinkLocale
        to="/"
        class="--logo"
      >
        <img
          :src="logoImage"
          alt="logo"
        />
      </NuxtLinkLocale>
      <div class="header-sidebar-controls">
        <div class="--search">
          <img src="~/assets/img/svg/common/search.svg" />
        </div>
        <div
          class="--toggle"
          @click="$emit('menuClick')"
        >
          <img src="~/assets/img/svg/common/menu.svg" />
        </div>
      </div>
    </div>
    <div class="header-sidebar-menu">
      <HeaderSidebarListItem
        v-for="item in mainMenuItems"
        :key="`mainMenuItem-${item.id}`"
        :item="item"
      />
      <HeaderSidebarListItem
        v-for="item in additionalMenuItems"
        :key="`additionalMenuItem-${item.id}`"
        :item="item"
      />
    </div>
    <div class="--botton-bar">
      <SelectLanguage />
      <SelectCurrency />
    </div>
  </div>
</template>

<script setup lang="ts">
import SelectLanguage from './SelectLanguageV2.vue';
import SelectCurrency from './SelectCurrencyV2.vue';
import HeaderSidebarListItem from '~/components/header/HeaderSidebarListItemV2.vue';
import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';

const appConfig = useAppConfigStore();

defineProps<{ additionalMenuItems: any, mainMenuItems: any }>();

const logoImage: ComputedRef<string> = computed(() => {
  return appConfig?.$state?.config?.logoBack;
});

defineEmits<{menuClick:[]}>();
</script>

<style lang="scss" scoped>
.header-sidebar {
  position: fixed;
  inset: 0;
  background: hsla(var(--white)/1);
  z-index: 110;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  padding: 20px;

  .--top-bar {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    align-items: center;
  }

  .--logo img {
    max-width: 185px;
    max-height: 40px;
    object-fit: contain;
    object-position: center;
  }

  .--botton-bar{
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

}

.header-sidebar-controls {
  display: flex;
  gap: 16px;

  .--search,
  .--toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 39px;
    height: 39px;
    border-radius: 10px;
    cursor: pointer;
    background: hsla(var(--link-tx)/0.2);
    border: 1px solid transparent;

    img {
      filter: var(--primary-filter-icon);
      width: 20px;
      height: 20px;
    }

    &:hover {
      border-color: hsla(var(--primary-bg)/1);
    }
  }
}
</style>
