<template>
  <div class="header-user-menu">
    <nuxt-link
      v-for="(_item, index) in userItemsLink"
      :key="`userItemsLink-${ index }`"
      :to="_item.path"
      class="header-user-menu__item"
      :class="{'--auth': isAuth}"
    >{{ _item.label }}</nuxt-link>
  </div>
</template>

<script setup lang="ts">
import type { ComputedRef } from 'vue';

const props = defineProps({
  isAuth: {
    type: Boolean,
    default: false
  }
});
const localePath = useLocalePath();
const {
  $i18n,
  $config
} = useNuxtApp();

const userItemsLink: ComputedRef<any[]> = computed(() => {
  const lcDomain = $config.public.baseDomainLc;
  return [
    {
      label: $i18n.t('header.wholesale_price'),
      path: props.isAuth ? lcDomain + '/' : localePath('/authorization')
    },
    {
      label: $i18n.t('header.request_stats'),
      path: props.isAuth ? lcDomain + '/statistics' : localePath('/authorization')
    },
    {
      label: $i18n.t('header.balance_history'),
      path: props.isAuth ? lcDomain + '/history' : localePath('/authorization')
    },
    {
      label: $i18n.t('header.referal_programm'),
      path: props.isAuth ? lcDomain + '/referral-program' : localePath('/authorization')
    },
    {
      label: $i18n.t('header.settings'),
      path: props.isAuth ? lcDomain + '/settings' : localePath('/authorization')
    }
  ]
});
</script>

<style scoped lang="scss">
.header-user-menu {
  display: flex;
  gap: 16px;
}
.header-user-menu__item {
  box-sizing: border-box;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 8px;

  font-size: 16px;
  font-weight: 500;
  line-height: 20.51px;
  letter-spacing: -0.02em;
  color: hsla(var(--link-tx)/1);

  &:hover {
    background: hsla(var(--primary-bg)/1);
    color: hsla(var(--primary-tx)/1);
  }
}

@media (max-width: 1199px) {
  .header-user-menu {
    gap: 8px;
  }
  .header-user-menu__item {
    padding: 4px;
    font-size: 15px;
  }
}
</style>
