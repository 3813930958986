<template>
  <div class="dropdown-header">
    <div
      class="dropdown-header__select"
      @click="changeOpen"
    >
      <img
        v-if="selectedIcon"
        :src="selectedIcon"
        class="--icon"
      />
      <span>{{ selectedLabel }}</span>
      <img
        src="~/assets/img/svg/common/arrow-drop-down.svg"
        class="--icon --icon-select"
      >
    </div>

    <template v-if="isOpen">
      <div class="dropdown-header__dropdown">
        <div
          v-for="(option) in options"
          :key="`option-${ option.value }`"
          class="dropdown-header__option"
          @click="() => clickOption(option)"
        >
          <template v-if="option.icon">
            <img
              :src="String(option.icon)"
              class="--icon"
            />
          </template>
          <span>{{ option.title }}</span>
        </div>
      </div>
      <div
        class="dropdown-header__close"
        @click="changeOpen"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { ComputedRef } from 'vue';
import type { IOptionControlDropDown } from '~/types/header';

interface IProps {
  value: any;
  options: IOptionControlDropDown[];
};
const props = withDefaults(defineProps<IProps>(), {
  options: () => []
});
const emits = defineEmits(['select']);

const selectedIcon: ComputedRef<any> = computed(() => {
  return props.options.find((t: IOptionControlDropDown) => t.value === props.value)?.icon;
});
const selectedLabel: ComputedRef<any> = computed(() => {
  return props.options.find((t: IOptionControlDropDown) => t.value === props.value)?.title;
});

const isOpen: Ref<boolean> = ref(false);
const changeOpen = () => {
  isOpen.value = !isOpen.value;
};

const clickOption = (item: any) => {
  emits('select', item);
  isOpen.value = false;
};
</script>

<style scoped lang="scss">
.dropdown-header {
  position: relative;
}
.dropdown-header__select {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 12px;
  box-sizing: border-box;
  border-radius: 10px;
  background: hsla(var(--link-tx)/0.2);
  cursor: pointer;
  border: 1px solid transparent;
  user-select: none;

  font-size: 18px;
  line-height: 1;
  font-weight: 500;
  color: color(hsla(var(--dark-gray-tx))/1);

  .--icon {
    width: 16px;
    opacity: 0.4;
  }
  .--icon-select{
    margin-left: auto;
  }
  &:hover {
    border-color: hsla(var(--primary-bg)/1);
  }
}
.dropdown-header__dropdown {
  position: absolute;
  z-index: 999;
  top: calc(100% + 4px); right: 0;
  min-width: 100%;
  padding: 6px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 6px 0px hsla(var(--primary-bg)/0.1);
  user-select: none;

  &:before {
    content: "";
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    z-index: -1;
    background: hsla(var(--link-tx)/0.2);
  }
  &:after {
    content: "";
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    z-index: -2;
    background: hsla(var(--white)/1);
  }
}
.dropdown-header__option {
  padding: 8px 12px;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;

  font-size: 14px;
  line-height: 1.1;
  font-weight: 500;
  color: hsla(var(--black)/1);
  letter-spacing: -0.03em;

  &:hover {
    background: hsla(var(--primary-bg)/1);
    color: hsla(var(--primary-tx)/1);
  }
}
.dropdown-header__close {
  position: fixed;
  top: 0; left: 0;
  right: 0; bottom: 0;
  z-index: 998;
}
</style>
