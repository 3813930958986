<template>
  <div
    ref="refElement"
    class="element-main-menu"
  >
    <nuxt-link
      :to="localePath(`/${item.path}`)"
      class="element-main-menu__item"
      @click.prevent="clickChildren"
    >
      {{ item.title }}
      <img v-if="isChildren" src="~/assets/img/svg/common/arrow-drop-down.svg" class="--arrow"/>
    </nuxt-link>
    <template v-if="isChildren">
      <div class="element-main-menu__dropdown">
        <nuxt-link
          v-for="(children) in childrenItems"
          no-prefetch
          :key="`element-main-menu__dropdown-${ children.id }`"
          :to="localePath(`/${children.path}`)"
          @click.prevent="clickChildren"
        >{{ children?.title }}</nuxt-link>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { ComputedRef } from 'vue';

interface IProps {
  item: any
}
const props = withDefaults(defineProps<IProps>(), {
  item: () => {
    return {};
  }
});

const localePath = useLocalePath();

const isChildren: ComputedRef<boolean> = computed(() => {
  return Boolean((childrenItems.value || []).length > 0)
});
const childrenItems: ComputedRef<any[]> = computed(() => {
  return (props.item?.children || [])
    .filter((t: any) => !Boolean(t.isHidden))
});

const refElement: Ref<any> = ref(null);
const clickChildren = () => {
  refElement.value.style.pointerEvents = 'none';
  setTimeout(() => {
    refElement.value.style.pointerEvents = '';
  }, 100)
};
</script>

<style lang="scss">
.element-main-menu {
  position: relative;
  &:hover {
    .element-main-menu__item {
      color: hsla(var(--primary-bg)/1);
      .--arrow {
        transform: rotate(180deg);
      }
    }
    .element-main-menu__dropdown {
      display: flex;
    }
  }
}
.element-main-menu__item {
  display: flex;
  align-items: center;
  margin: -14px 0;
  cursor: pointer;
  gap: 4px;

  font-weight: 500;
  font-size: 19px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.015em;
  color: hsla(var(--dark-gray-tx)/0.7);

  .--arrow {
    width: 10px;
    height: 10px;
    object-fit: contain;
    filter: var(--primary-filter-icon);
    pointer-events: none;
  }
}
.element-main-menu__dropdown {
  display: none;
  flex-direction: column;
  position: absolute;
  top: calc(100% + 8px); left: 0;
  background-color: hsla(var(--main-section-bg)/1);
  box-shadow: 0px 4px 8px 0px hsla(0, 0%, 0%, 0.05);
  padding: 4px 0;
  border-radius: 10px;
  overflow: hidden;

  & > * {
    padding: 5px 16px;
    box-sizing: border-box;
    cursor: pointer;

    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: hsla(var(--main-section-tx)/1);
    white-space: nowrap;

    &:hover {
      background-color: hsla(var(--primary-bg)/1);
      color: hsla(var(--primary-tx)/1);
    }
  }
}

@media (max-width: 1399px) {
  .element-main-menu__item {
    font-size: 18px;
  }
}
@media (max-width: 1199px) {
  .element-main-menu__item {
    font-size: 15px;
  }
}
</style>
