<template>
  <DropdownHeader
    :value="selectedLanguages"
    :options="optionsLanguages"
    @select="changeLanguages"
  />
</template>

<script setup lang="ts">
import type { ComputedRef } from 'vue';
import DropdownHeader from './_DropdownHeaderV2.vue';
import type { IOptionControlDropDown } from '~/types/header';
const {
  $i18n
} = useNuxtApp();

const selectedLanguages: ComputedRef<any> = computed(() => {
  return $i18n.locale?.value || 'en';
});
const optionsLanguages: ComputedRef<IOptionControlDropDown[]> = computed(() => {
  return [
    { title: 'RU', value: 'ru' },
    { title: 'EN', value: 'en' }
  ];
});
const changeLanguages = async (_val: any) => {
  const localeCode: string = _val?.value;
  await $i18n.setLocale(localeCode);
  await $i18n.setLocaleCookie(localeCode);
  window.location.reload();
};
</script>
