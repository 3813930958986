<template>
  <div class="user-mobile-menu">
    <div
      class="user-mobile-menu__main"
      @click="openDropdown"
    >
      <nuxt-link
        v-if="!isAuth"
        :to="localePath('/authorization')"
        class="user-mobile-menu__account"
      >
        <img class="" src="~/assets/img/svg/common/user-circle.svg"/>
        <span class="--text">Перейти в ЛК</span>
        <img class="--arrow" src="~/assets/img/svg/common/arrow.svg"/>
      </nuxt-link>
      <div
        v-if="isAuth"
        class="user-mobile-menu__account --auth"
        :class="{'--dropdown': isOpen}"
      >
        <img class="" src="~/assets/img/svg/common/user-circle.svg"/>
        <span class="--text">Перейти в ЛК</span>
        <span class="--balance">{{ balanceLabel }}</span>
        <img class="--arrow" src="~/assets/img/svg/common/arrow.svg"/>
      </div>

      <nuxt-link
        v-if="!isAuth"
        :to="localePath('/registration')"
        class="user-mobile-menu__registration btn btn-primary"
      >{{ $t('registration.title') }}</nuxt-link>
    </div>

    <template v-if="isAuth && isOpen">
      <div class="user-mobile-menu__dropdown">
        <nuxt-link
          v-for="(item) in userMenu"
          :key="`item-${ item.path }`"
          :to="item.path"
          @click="openDropdown"
        >{{ item.label }}</nuxt-link>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { ComputedRef } from 'vue';
import { useSmsUser } from '~/store/sms-user';

const $i18n = useI18n();
const {
  $config,
  $numberFormat
} = useNuxtApp();
const smsUser = useSmsUser();
const localePath = useLocalePath();

const isAuth: ComputedRef<boolean> = computed(() => {
  return true
});

const isOpen: Ref<boolean> = ref(false);
const openDropdown = () => {
  if (!isAuth) {
    return
  }
  isOpen.value = !isOpen.value;
};

const userMenu: ComputedRef<any[]> = computed(() => {
  const lcDomain = $config.public.baseDomainLc;
  return [
    {
      label: $i18n.t('header.wholesale_price'),
      path: isAuth.value ? lcDomain + '/' : localePath('/authorization')
    },
    {
      label: $i18n.t('header.request_stats'),
      path: isAuth.value ? lcDomain + '/statistics' : localePath('/authorization')
    },
    {
      label: $i18n.t('header.balance_history'),
      path: isAuth.value ? lcDomain + '/history' : localePath('/authorization')
    },
    {
      label: $i18n.t('header.referal_programm'),
      path: isAuth.value ? lcDomain + '/referral-program' : localePath('/authorization')
    },
    {
      label: $i18n.t('header.settings'),
      path: isAuth.value ? lcDomain + '/settings' : localePath('/authorization')
    }
  ]
});

const balanceLabel: ComputedRef<string> = computed(() => {
  let _balance = $numberFormat(smsUser.balance);
  return [_balance, '₽'].join(' ')
});
</script>

<style scoped lang="scss">
.user-mobile-menu {
  position: relative;
}
.user-mobile-menu__main {
  display: flex;
  gap: 8px;
}
.user-mobile-menu__account {
  flex: auto;
  display: flex;
  align-items: center;
  padding: 0 12px;
  gap: 8px;
  height: 42px;
  border-radius: 12px;
  background: hsla(var(--link-tx)/0.2);

  img {
    filter: var(--primary-filter-icon);
    opacity: 0.7;
  }
  .--text {
    margin-right: auto;
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    letter-spacing: -0.02em;
    color: hsla(var(--link-tx)/1);
  }
  .--arrow {
    transform: rotate(270deg);
  }
  .--balance {
    font-size: 16px;
    line-height: 1;
    color: hsla(var(--primary-tx)/1);
    font-weight: 600;
  }
  &.--dropdown .--arrow {
    transform: rotate(90deg);
  }
}
.user-mobile-menu__account.--auth {
  background: hsla(var(--primary-bg)/1);

  img {
    filter: invert(1);
    opacity: 1;
  }
  .--text {
    color: hsla(var(--primary-tx)/1);
  }
}
.user-mobile-menu__registration {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 0 20px;
  height: 42px;

  font-size: 16px;
}

.user-mobile-menu__dropdown {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  top: calc(100% + 4px);
  left: 0; right: 0;
  padding: 9px 3px;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px 0px hsla(var(--black)/0.15);
  background: hsla(var(--white)/1);
  border-radius: 10px;

  a {
    padding: 9px 16px;
    box-sizing: border-box;
    border-radius: 8px;

    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    color: hsla(var(--dark-gray-tx)/1);
  }
  .router-link-active {
    background: hsla(var(--primary-bg)/1);
    color: hsla(var(--primary-tx)/1);
    font-weight: 600;
  }
}
</style>
