<template>
  <div class="header-top-controls">
    <div class="--search" @click="openSearch">
      <img src="~/assets/img/svg/common/search.svg"/>
      <span>Поиск</span>
    </div>
    <div class="--language">
      <DropdownHeader
        :value="selectedLanguages"
        :options="optionsLanguages"
        @select="changeLanguages"
      />
    </div>
    <div class="--currency">
      <DropdownHeader
        :value="selectedCurrency"
        :options="optionsCurrency"
        @select="changeCurrency"
      />
    </div>
    <div
      class="--sidebar"
      @click="$emit('menuClick')"
    >
      <img src="~/assets/img/svg/common/menu.svg" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ComputedRef } from 'vue';
import DropdownHeader from "./_DropdownHeaderV2.vue";
import type { IOptionControlDropDown } from '~/types/header';
import { useSearchStore } from '~/store/search';

const {
  $i18n
} = useNuxtApp();

const selectedCurrency: ComputedRef<any> = computed(() => {
  return "rub"
});
const optionsCurrency: ComputedRef<IOptionControlDropDown[]> = computed(() => {
  return [
    {title: "₽", value: "rub"},
    {title: "$", value: "usd"},
  ]
});
const changeCurrency = (_val: any) => {};

const selectedLanguages: ComputedRef<any> = computed(() => {
  return $i18n.locale?.value || 'en'
});
const optionsLanguages: ComputedRef<IOptionControlDropDown[]> = computed(() => {
  return [
    {title: "RU", value: "ru"},
    {title: "EN", value: "en"},
  ]
});
const changeLanguages = async (_val: any) => {
  const localeCode: string = _val?.value;
  await $i18n.setLocale(localeCode);
  await $i18n.setLocaleCookie(localeCode);
  window.location.reload();
};

const searchStore = useSearchStore();
const openSearch = () => {
  searchStore.changeOpen(true);
};
</script>

<style scoped lang="scss">
.header-top-controls {
  display: flex;
  gap: 12px;
}
.header-top-controls .--search {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  padding-right: 32px;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
  background: hsla(var(--link-tx)/0.2);
  border: 1px solid transparent;

  img {
    filter: var(--primary-filter-icon);
  }
  span {
    font-size: 16px;
    line-height: 1;
    letter-spacing: -0.02em;
    color: hsla(var(--link-tx)/1);
  }

  &:hover {
    border-color: hsla(var(--primary-bg)/1);
  }
}
.header-top-controls .--sidebar {
  display: none;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
  background: hsla(var(--link-tx)/0.2);
  border: 1px solid transparent;

  img {
    filter: var(--primary-filter-icon);
  }
}

@media (max-width: 1199px) {
  .header-top-controls {
    gap: 6px;
  }
  .header-top-controls .--sidebar {
    display: flex;
  }
}
@media (max-width: 639px) {
  .header-top-controls {
    gap: 4px;
  }
  .header-top-controls .--search {
    padding: 6px;
    span {
      display: none;
    }
  }
  .header-top-controls .--currency {
    display: none;
  }
}
</style>
